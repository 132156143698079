import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// import api from './api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const login = async ({ username, password }) => {
    try {
      const response = await axios.post('https://dev.toporin.com/wp-json/jwt-auth/v1/token', {
        username: username,
        password: password,
      });

      // console.log(response);

      setCookies('token', response.data.token);

      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    ['token'].forEach((obj) => removeCookie(obj));
    navigate('/login');
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
