import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  Table,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
} from '@mui/material';

import { useAuth } from '../hooks/useAuth';

const API_LINK = 'https://api.clienttracker.toporin.com/api';

const formatPhoneNumber = (phone) => {
  let cleaned = ('' + phone).replace(/\D/g, '');

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

const Dashboard = () => {
  const { logout } = useAuth();

  const [jobs, setJobs] = useState();
  const [forms, setForms] = useState();
  const [dataToShow, setDataToShow] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Action functions
  const collectData = () => {
    setDataToShow();

    let city;

    switch (trackingNumber) {
      case '+17372605877':
        city = 'austin';
        break;
      case '+14696528559':
        city = 'dallas';
        break;
      case '+17207820861':
        city = 'denver';
        break;
      case '+13239005813':
        city = 'losangeles';
        break;
      case '+19496170704':
        city = 'orangecounty';
        break;
      case '+12676197579':
        city = 'philadelphia';
        break;
      case '+15038098415':
        city = 'portland';
        break;
      case '+17252174542':
        city = 'lasvegas';
        break;
      case '+12062076671':
      case '+12066723215':
        city = 'seattle';
        break;
      case '+18583765115':
        city = 'sandiego';
        break;
      default:
        city = 'all';
        break;
    }

    if (city !== 'all') {
      const filteredJobs =
        jobs
          ?.filter((el) => el.call.tracking_phone_number === trackingNumber)
          .map((el) => ({
            name: `${el.customer?.first_name} ${el.customer?.last_name !== null ? el.customer?.last_name : ''}`,
            address: `${el.address?.street}${
              el.address?.street_line_2 !== null ? ` ${el.address?.street_line_2}` : ''
            }, ${el.address?.city}, ${el.address?.state}, ${el.address?.zip}`,
            phone: el.customer?.mobile_number,
            createdAt: moment(el.created_at).format('MM/DD/YYYY'),
            source: 'CALL',
            items: el.items,
          })) || [];

      const filteredForms =
        forms
          ?.filter((el) => el.city === city)
          .map((el) => ({
            name: `${el.firstName} ${el.lastName}`,
            address: 'N/A',
            phone: el.phoneNumber,
            createdAt: moment(el.createdAt).format('MM/DD/YYYY'),
            source: 'FORM',
            items: [
              {
                name: el.service,
              },
            ],
          })) || [];

      // setDataToShow([...filteredJobs, ...filteredForms]);
      setDataToShow([...filteredJobs]);
    } else {
      const filteredJobs =
        jobs?.map((el) => ({
          name: `${el.customer?.first_name} ${el.customer?.last_name !== null ? el.customer?.last_name : ''}`,
          address: `${el.address?.street}${
            el.address?.street_line_2 !== null ? ` ${el.address?.street_line_2}` : ''
          }, ${el.address?.city}, ${el.address?.state}, ${el.address?.zip}`,
          phone: el.customer?.mobile_number,
          createdAt: moment(el.created_at).format('MM/DD/YYYY'),
          source: 'CALL',
          items: el.items,
        })) || [];

      const filteredForms =
        forms?.map((el) => ({
          name: `${el.firstName} ${el.lastName}`,
          address: 'N/A',
          phone: el.phoneNumber,
          createdAt: moment(el.createdAt).format('MM/DD/YYYY'),
          source: 'FORM',
          items: [
            {
              name: el.service,
            },
          ],
        })) || [];

      // setDataToShow([...filteredJobs, ...filteredForms]);
      setDataToShow([...filteredJobs]);
    }
  };

  // Handle functions
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  // Async functions
  const getJobs = async () => {
    try {
      const response = await axios.get(`${API_LINK}/jobs?dateFrom=${startDate}&dateTo=${endDate}`, {
        headers: {
          Authorization: 'Basic YXBpYWRtaW46K0Y7TyFeOCM5RXo1',
          // 'Content-Type': 'application/json',
        },
        withCredentials: false,
      });

      // console.log(response.data);

      if (response.status === 206)
        setJobs(
          response.data.reduce((o, i) => {
            if (!o.find((v) => v.call.customer_phone_number === i.call.customer_phone_number)) {
              o.push(i);
            }
            return o;
          }, []),
        );
    } catch (error) {
      console.log(error);
    }
  };

  const getForms = async () => {
    try {
      const response = await axios.get(`${API_LINK}/form/filtered?dateFrom=${startDate}&dateTo=${endDate}`, {
        headers: {
          Authorization: 'Basic YXBpYWRtaW46K0Y7TyFeOCM5RXo1',
          // 'Content-Type': 'application/json',
        },
        withCredentials: false,
      });

      // console.log(response.data);

      if (response.status === 206)
        setForms(
          response.data.reduce((o, i) => {
            if (!o.find((v) => v.phoneNumber === i.phoneNumber && v.email === i.email)) {
              o.push(i);
            }
            return o;
          }, []),
        );
    } catch (error) {
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    if (startDate && endDate) {
      getJobs();
      getForms();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    collectData();
  }, [jobs, forms, trackingNumber]);

  // console.log('forms', forms);
  // console.log('jobs', jobs);

  // console.log(trackingNumber);
  // console.log(dataToShow);

  return (
    <Box component="section" style={{ maxWidth: 1200, margin: '50px auto 0' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                label="Start Date"
                onChange={(newDate) => setStartDate(moment(new Date(newDate)).format('YYYY-MM-DD'))}
                sx={{ p: 0 }}
              />

              <DatePicker
                label="End Date"
                onChange={(newValue) => setEndDate(moment(new Date(newValue)).add(24, 'h').format('YYYY-MM-DD'))}
                sx={{ p: 0 }}
                disabled={!startDate}
              />
            </DemoContainer>
          </LocalizationProvider>

          <FormControl sx={{ width: 200, height: 50 }}>
            <InputLabel id="leadgen-label">Leadgen</InputLabel>
            <Select
              labelId="leadgen-label"
              label="Leadgen"
              onChange={(event) => setTrackingNumber(event.target.value)}
              value={trackingNumber || ''}
              disabled={!startDate || !endDate}
            >
              <MenuItem value={`all`}>ALL</MenuItem>
              <MenuItem value={`+17372605877`}>AUSTIN | 5877</MenuItem>
              <MenuItem value={`+14696528559`}>DALLAS | 8559</MenuItem>
              <MenuItem value={`+17207820861`}>DENVER | 0861</MenuItem>
              <MenuItem value={`+17252174542`}>LAS VEGAS | 4542</MenuItem>
              <MenuItem value={`+13239005813`}>LOS ANGELES | 5813</MenuItem>
              <MenuItem value={`+19496170704`}>ORANGE COUNTY | 0704</MenuItem>
              <MenuItem value={`+12676197579`}>PHILADELPHIA | 7579</MenuItem>
              <MenuItem value={`+15038098415`}>PORTLAND | 8415</MenuItem>
              <MenuItem value={`+18583765115`}>SAN DIEGO | 5115</MenuItem>
              <MenuItem value={`+12062076671`}>SEATTLE | 6671</MenuItem>
              <MenuItem value={`+12066723215`}>SEATTLE | 3215</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button variant="contained" onClick={logout}>
          Log Out
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#eee' }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Customer Phone</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Items</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow
              ?.sort(function (a, b) {
                if (a.createdAt < b.createdAt) return 1;

                return -1;
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((el, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.address}</TableCell>
                  <TableCell>{formatPhoneNumber(el.phone)}</TableCell>
                  <TableCell>{el.createdAt}</TableCell>
                  <TableCell>{el.source}</TableCell>
                  <TableCell>
                    {el.items?.map((item, index) => (
                      <Box key={index}>
                        <Typography>
                          {item.name} {item.unit_price && `($${item.unit_price})`}
                        </Typography>
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={dataToShow.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Typography fontSize={26} sx={{ mt: 2, fontWeight: 'bold' }}>
        Total Clients: {dataToShow.length}
      </Typography>
    </Box>
  );
};

export default Dashboard;
