import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import axios from 'axios';

import { useAuth } from './hooks/useAuth';

import { ProtectRoutes } from './hooks/protectRoutes';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

const App = () => {
  const { cookies, logout } = useAuth();

  const validateToken = async () => {
    try {
      const response = await axios.post('https://dev.toporin.com/wp-json/jwt-auth/v1/token/validate', null, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });

      if (response.status === 403) logout();
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  useEffect(() => {
    return () => cookies.token && validateToken();
  }, [cookies.token]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
};

export default App;
